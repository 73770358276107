import React, { useEffect, useRef } from "react";
import { window } from "browser-monads";
import chevron from "../../images/logos/chevron.png";
import mol from "../../images/logos/mol.png";
import oiltanking from "../../images/logos/oiltanking.png";
import omv from "../../images/logos/omv.png";
import propatria from "../../images/logos/propatria.jpg";
import sanofi from "../../images/logos/sanofi.png";
import wintershall from "../../images/logos/wintershall.png";

import "../../styles/companies.css";

function Compaines() {
  const ref = useRef(null);
  const observer = new window.IntersectionObserver((entries) => {
    if (!entries[0].isIntersecting) return;
    for (let child of ref.current.children) {
      const src = child.children[0].getAttribute("data-src");
      child.children[0].setAttribute("src", src);
    }
  });

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.unobserve(ref.current);
  }, []);

  return (
    <div ref={ref} className="companies__wrapper">
      <div>
        <img data-src={chevron} alt="chevron company icon" />
      </div>
      <div>
        <img data-src={mol} alt="mol company icon" />
      </div>
      <div>
        <img data-src={oiltanking} alt="oiltanking company icon" />
      </div>
      <div>
        <img data-src={omv} alt="omv company icon" />
      </div>
      <div>
        <img data-src={propatria} alt="propatria company icon" />
      </div>
      <div>
        <img data-src={sanofi} alt="sanofi company icon" />
      </div>
      <div>
        <img data-src={wintershall} alt="wintershall company icon" />
      </div>
    </div>
  );
}

export default Compaines;
