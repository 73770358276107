import React, { useEffect, useRef, useState } from "react";
import { window } from "browser-monads";
import dollar from "../../images/icons/dollar.svg";
import gavel from "../../images/icons/gavel.svg";
import fire from "../../images/icons/fire.svg";
import male from "../../images/icons/male.svg";
import cube from "../../images/icons/cube.svg";
import windowIcon from "../../images/icons/window-close.svg";
import "../../styles/cumulatedData.css";

function animateValue(setNumber, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    setNumber(Math.floor(progress * (end - start) + start));
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

function addComas(nStr) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

const data = [
  {
    icon: dollar,
    number: 10032250000,
    text: "ESTIMATED COST OF THE DISASTERS",
  },
  { icon: gavel, number: 12, text: "MANAGERS / DIRECTORS ARRESTED" },
  { icon: fire, number: 1096933, text: "CUBIC METERS OF FUEL LOST" },
  { icon: male, number: 2338, text: "CASUALTIES: 2040 INJURED, 297 DEAD" },
  {
    icon: cube,
    number: 1787094,
    text: "CUBIC METERS OF STORAGE CAPACITY DESTROYED",
  },
  { icon: windowIcon, number: 147, text: "STORAGE TANKS DESTROYED" },
];

function CumulatedData() {
  const [state, setState] = useState({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
  const ref = useRef(null);
  const observer = new window.IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      data.forEach((d, i) => {
        animateValue(
          (n) => setState((prev) => ({ ...prev, [i]: n })),
          0,
          d.number,
          2000
        );
      });
    }
  });

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.unobserve(ref.current);
  }, []);

  return (
    <div className="cumulated__data">
      <h1>Cumulated data of the major tank fire incidents since 2001 ⋆</h1>
      <div ref={ref} className="cumulated">
        {data.map((d, i) => {
          return (
            <div key={i} className="cumulated__group">
              <img src={d.icon} alt="icon" />
              <p>{addComas(state[i])}</p>
              <p>{d.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CumulatedData;
