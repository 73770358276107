import React from "react";
import "../../styles/sixpoints.css";

const data = [
  {
    short:
      "It can put out the biggest full-surface storage-tank fires in under π (3.14) minutes.",
    long: "",
    icon: "",
  },
  {
    short:
      "No electricity, no moving parts, no water network, no human resource required.",
    long: "",
    icon: "",
  },
  {
    short:
      "Worldwide unique, AI-based simulation software that optimizes the design for various tank sizes.",
    long: "",
    icon: "",
  },
];

const data2 = [
  {
    short:
      "Providing 100% safety during pandemics, natural disasters, and in resource-limited areas.",
    long: "",
    icon: "",
  },
  {
    short:
      "Works perfectly with (a) our fluorene-free, biodegradable, non-PBT - even edible - foam, (b) as well as our patented Impulse Gun SystemTM, which is ideal for putting out liquid-jet fires and blazes in oil and gas wells.",
    long: "",
    icon: "",
  },
  { short: "Extremely low CAPEX and OPEX.", long: "", icon: "" },
];

function SixPoints() {
  return (
    <>
      <h2>6 reasons to install the Pi Foam System</h2>
      <div className="points">
        <div className="points1">
          {data.map((d) => {
            return (
              <div className="point__group">
                <p>{d.short}</p>
                <p>{d.long}</p>
              </div>
            );
          })}
        </div>
        <div className="points2">
          {data2.map((d) => {
            return (
              <div className="point__group">
                <p>{d.short}</p>
                <p>{d.long}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SixPoints;
