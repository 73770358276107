import React from "react";
import factory from "../../images/icons/database.svg";
import stock from "../../images/icons/arrow.svg";
import tree from "../../images/icons/tree.svg";
import damage from "../../images/icons/damage.svg";
import "../../styles/figures.css";

function Figures() {
  return (
    <>
      <h2>⋆These figures DO NOT include the cost of:</h2>
      <ul>
        <li>
          <img src={factory} alt="icon" />
          <p>factory shutdown and production shortfalls</p>
        </li>
        <li>
          <img src={tree} alt="icon" />
          <p>environmental damage and contamination</p>
        </li>
        <li>
          <img src={damage} alt="icon" />
          <p>brand-image damage, etc.</p>
        </li>
        <li>
          <img src={stock} alt="icon" />
          <p>stock-market losses</p>
        </li>
      </ul>
    </>
  );
}

export default Figures;
