import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import oiltaking from "../../images/oiltaking.png";
import tuvsud from "../../images/tüv_süd.png";
import ibmb from "../../images/ibmb.jpg";
import "../../styles/testemonials.css";

function Testemonials() {
  return (
    <div class="testemonials__wrapper">
      <AwesomeSlider>
        <div className="testemonials__group">
          <img src={oiltaking} alt="oiltanking company logo" />
          <p>
            “We have been using the earlier version of this system for many
            years. It works perfectly in our regular operation tests, while our
            maintenance costs are marginal compared to a regular built-in system
            or a facility fire brigade.”
          </p>
        </div>
        <div className="testemonials__group">
          <img src={tuvsud} alt="tüv süd company logo" />
          <p>
            “As demonstrated by a fire-extinguishing test witnessed by a TÜV
            expert, the fire detection, the automatic activation of the
            fire-extinguishing system and the direct fighting of the incipient
            fire took place so quickly that the system stopped it from fully
            developing… The high application rate of the fire-extinguishing
            agent ensures that the fire is extinguished very quickly, thus
            minimizing any environmental pollution caused by the fire and the
            foam layer.”
          </p>
        </div>
        <div className="testemonials__group">
          <img src={ibmb} alt="Technical University of Braunschweig logo" />
          <p>
            The Pressurized Instant (Pi/π) Foam System will be referenced and
            explained in the 6th edition of the technical publication
            "Abwehrender und Anlagentechnischer Brandschutz - fuer Architekten,
            Bauingenieure und Feuerwehringenieure" Author:{" "}
            <a
              href="http://www.ibmb.tu-braunschweig.de/index.php/Hans-Joachim_Gressmann.html"
              target="blank"
            >
              Prof. Hans-Joachim Gressmann
            </a>{" "}
            Technical University of Braunschweig, Germany.
          </p>
        </div>
      </AwesomeSlider>
    </div>
  );
}

export default Testemonials;
