import React, { useEffect, useCallback, useRef } from "react";
import { window } from "browser-monads";
import Layout from "../components/Layout";
import CumulatedData from "../components/sections/CumulatedData";
import Figures from "../components/sections/Figures";
import { withPrefix } from "gatsby";
import SixPoints from "../components/sections/SixPoints";
import Testemonials from "../components/sections/Testemonials";
import Compaines from "../components/sections/Compaines";
import { to as scrollTo } from "zenscroll";
import "../styles/global.css";
import "../styles/index.css";

const IndexPage = () => {
  const video1 = useRef(null);
  const video2 = useRef(null);
  const videos = useRef(null);
  const benefits = useRef(null);
  const observer = new window.IntersectionObserver((entries) => {
    for (let entry of entries) {
      if (entry.isIntersecting) {
        if (video1.current == entry.target) {
          const src = video1.current.getAttribute("data-src");
          video1.current.setAttribute("src", src);
          observer.unobserve(video1.current);
        } else if (video2.current == entry.target) {
          const src = video2.current.getAttribute("data-src");
          video2.current.setAttribute("src", src);
          observer.unobserve(video2.current);
        }
      }
    }
  });

  const resizeHandler = useCallback(() => {
    if (window.innerWidth <= 680) {
      const width = window.innerWidth - 40;
      const height = width / (640 / 360);
      video1.current.setAttribute("width", width);
      video1.current.setAttribute("height", height);
      video2.current.setAttribute("width", width);
      video2.current.setAttribute("height", height);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    const width = window.innerWidth < 640 ? window.innerWidth - 40 : 640;
    const height = width / (640 / 360);
    video1.current.setAttribute("width", width);
    video1.current.setAttribute("height", height);
    video2.current.setAttribute("width", width);
    video2.current.setAttribute("height", height);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    observer.observe(video1.current);
    observer.observe(video2.current);
    return () => {
      observer.unobserve(video1.current);
      observer.unobserve(video2.current);
    };
  }, []);

  return (
    <Layout>
      <main>
        <section
          className="landing bg-image"
          style={{
            backgroundImage: `url("${withPrefix("/pifoam-bg.jpg")}")`,
          }}
        >
          <h1>
            Pi Foam System – Extinguishing Tank Fires in{" "}
            <span style={{ color: "#e10707" }}>π</span> Minutes
          </h1>
          <div className="landing__actions">
            <button onClick={() => scrollTo(benefits.current)}>
              See the benefits
            </button>
            <button onClick={() => scrollTo(videos.current)}>
              Watch the Videos
            </button>
          </div>
        </section>

        <section ref={videos} className="video__section" id="videos">
          <div className="videos">
            <div className="video__wrapper">
              <iframe
                data-src="https://drive.google.com/file/d/1R3fyXzmntAHoFbIRTEhsmwGe27CGYA_7/preview"
                ref={video1}
                frameBorder="0"
                allowFullScreen=""
                allow="fullscreen"
                id="player_1"
                data-ready="true"
              />
            </div>
            <div className="video__wrapper">
              <iframe
                data-src="https://player.vimeo.com/video/325744124?autoplay=0&amp;loop=1"
                ref={video2}
                frameBorder="0"
                allowFullScreen=""
                allow="fullscreen"
                id="player_3"
                data-ready="true"
              />
            </div>
          </div>
          <h2>
            Please find THE SAME Executive Summary video ONLY with subtitles{" "}
            <a href="https://vimeo.com/263745508/41a9d42d26" target="blank">
              here
            </a>
          </h2>
        </section>
        <section
          className="cumulated__section bg-image"
          style={{
            backgroundImage: `url("${withPrefix("/pifoam-bg.jpg")}")`,
          }}
        >
          <CumulatedData />
        </section>
        <section className="figures">
          <Figures />
        </section>
        <section className="sixpoints" id="benefits" ref={benefits}>
          <SixPoints />
        </section>
        <section className="testemonials">
          <h2>Testimonials</h2>
          <Testemonials />
        </section>
        <section className="companies">
          <h2>
            These companies are already using Pi Foam System’s earlier versions
          </h2>
          <Compaines />
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
